import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from  '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { NgxRandomModule} from "ngx-random";
import { ZXingScannerModule  } from '@zxing/ngx-scanner';

import { HomeComponent } from './home/home.component';
import { RegisterProductComponent } from './register-product/register-product.component';
import { RegisterCustomerComponent } from './register-customer/register-customer.component';
import { ConfirmRegistrationComponent } from './confirm-registration/confirm-registration.component';
import { OfferProductComponent } from './offer-product/offer-product.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { ConfirmPurchaseComponent } from './confirm-purchase/confirm-purchase.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RegisterProductComponent,
    RegisterCustomerComponent,
    ConfirmRegistrationComponent,
    OfferProductComponent,
    CustomerDetailsComponent,
    ConfirmPurchaseComponent,
    FooterComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgxOtpInputModule,
    NgxRandomModule,
    ZXingScannerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
