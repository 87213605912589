import { Component, OnInit } from '@angular/core';

//------------------- Add on imports ----------------------
import { ApiService } from '../api.service';
import { SharedDataService } from '../shared-data.service';
import { NotificationService } from '../notification.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor( 
    public apiService:ApiService, 
    private dataService: SharedDataService,
    private nofifyservice: NotificationService,
    private route:ActivatedRoute,
    private router:Router
  ){}

  data: any;
  companyId: any;
  companyDetails: any;
  companyName: any;
  companyOwner: any;
  companyEmail: any;
  companyPh: any;
  companyGstn: any;
  accountManager: any;
  accountManagerPh: any;
  accountManagerEmail: any;
  regionalManager: any;
  regionalManagerPh: any;
  regionalManagerEmail: any;
  soldProducts: any;
  rewardPoints: any;
  welcomeTextLine1: any;
  welcomeTextLine2: any;
  scannerview: any;
	  
  ngOnInit() {

    //this.companyId = "14";
    this.companyId = this.route.snapshot.paramMap.get('did');
    if(this.companyId){}else{
      this.companyId =this.route.snapshot.queryParamMap.get('did');
    }
    if(this.companyId){

      this.data = '{' + '"company_id":' + '"' + this.companyId + '"' + '}';
    this.dataService.globalcompanyData = {companyId: this.companyId};
    this.rewardPoints = 0;
    sessionStorage.setItem('companyId', this.companyId);
    
      this.apiService.postRequest('/getHomeData', this.data).subscribe((res: any) => {
        if (typeof res === 'object') {
          if (res.msg === "Success") {
            this.scannerview=false;
            this.companyDetails = res.company[0];
            this.companyName = this.companyDetails.company_name;
            this.companyOwner = this.companyDetails.name;
            this.companyEmail = this.companyDetails.email;
            this.companyPh = this.companyDetails.mobile;
            this.companyGstn = this.companyDetails.gst_number;
            this.accountManager = this.companyDetails.account_manager;
            this.accountManagerPh = this.companyDetails.am_phone;
            this.accountManagerEmail = this.companyDetails.am_email;
            this.regionalManager = this.companyDetails.regional_manager;
            this.regionalManagerPh = this.companyDetails.rm_phone;
            this.regionalManagerEmail = this.companyDetails.rm_email;
            this.soldProducts = res.count;
            this.rewardPoints = res.sum;
            if (this.soldProducts > 0) {this.welcomeTextLine1 = "You have sold "+ this.soldProducts + " WaterScience Products"; }
            else { this.welcomeTextLine1 = "You haveYou haven't sold any WaterScience products yet"; }        
            if (this.soldProducts > 0) {this.welcomeTextLine2 = "You have "+ this.rewardPoints + " reward Points"; }
            else { this.welcomeTextLine2 = "Sell products to get rewards"; }      
          }
          else {
            this.scannerview=true;
            this.nofifyservice.showWarning ("Company details not available for - " + this.companyId,'');
          }
      }
    });


    }
    else
    {
      this.scannerview=true;
      this.nofifyservice.showWarning ("Please Scan your Dealer QR Code. ",'');
    }
    
	
  }

  captureImage(resultString: string) {
    alert(resultString);
  }
 
}
