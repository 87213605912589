<app-header></app-header>
<main id="main" class="p-t-50">
    <section >
       <div class="container" data-aos="fade-up">

            <div class="col-sm-10 col-lg-7 col-xl-5 m-lr-auto m-b-50">
                <div class="card pxy25">
                <div class="p-b-35 center full-width">

                    <div class="section-header">
                        <h2>Product Registration</h2>
                    </div> 

                    <button class="stext-101 cl0 size-116 bg-btn-bluegrey bor14 hov-btn-custom p-lr-15 trans-04 pointer">
                        <a *ngIf="showScanner" id="startBtn" (click)="scanCode(false)" class="black-txt hov-btn-custom"><i class="zmdi zmdi-center-focus-weak"></i> &nbsp;Stop Scanning</a>
                        <a *ngIf="!showScanner" id="stopBtn" (click)="scanCode(true)" class="black-txt hov-btn-custom"><i class="zmdi zmdi-center-focus-weak"></i> &nbsp;Scan your Product</a>	
                    </button>
                </div>

                <div *ngIf="showScanner" class="p-b-35 center full-width">
                    <zxing-scanner  #scanner (scanSuccess)="onCodeResult($event)" [formats]="allowedFormats" [autofocusEnabled]="true" [torch]="true"  [tryHarder]="true"></zxing-scanner>
                </div>
                <div class="p-b-35 center full-width" style="display:none;">                
                    <button class="stext-101 cl0 size-116 bg-btn-bluegrey bor14 hov-btn-custom p-lr-15 trans-04 pointer">
                        <a (click)="getProductDetailsbySlNo()" class="black-txt hov-btn-custom"><i class="zmdi zmdi-center-focus-weak"></i> &nbsp; Get Product Details</a>	
                    </button>
                </div>
                <!-- div [hidden]="!action.isStart">
                    <ngx-scanner-qrcode #action="scanner" [config]="config" (event)="onEvent($event)" (error)="onError($event)"></ngx-scanner-qrcode>								
                    <button class="btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="action.isLoading">
                        <img *ngIf="!action.isStart" (click)="handle(action, 'start')" src="https://img.icons8.com/ios/50/000000/no-camera--v2.png" width="30px"/>
                        <img *ngIf="action.isStart" (click)="handle(action, 'stop')" src="https://img.icons8.com/ios/50/000000/no-camera--v1.png" width="30px"/>
                    </button>
                </div -->            
                <div class="row p-b-10">
                  <div class="input-group mb-3">
                    <input type="text"  class="form-control" aria-describedby="basic-addon2" name="serial-no" placeholder="Serial No" [(ngModel)]="productSerialNo">
                    <div class="input-group-append">
                      <button  class="btn btn-primary" (click)="getProductDetailsbySlNo()" type="button">Get</button>
                    </div>
                  </div>
                    
                </div>
                </div>


                <div class="row" *ngIf="!showScanner"  >



                    <section *ngIf="this.productModelName">
                        <div class="container py-5">
                          <div class="row justify-content-center">
                            <div class="col-md-12 col-lg-12 col-xl-12">
                              <div class="card" style="border-radius: 15px;">
                                <div class="bg-image hover-overlay ripple ripple-surface ripple-surface-light"
                                  data-mdb-ripple-color="light">
                                  <img src="assets/img/Shower_Tap_Mobile_1.webp" style="border-top-left-radius: 15px; border-top-right-radius: 15px; width: 100%;" class="img-fluid"
                                    alt="Laptop" />
                                  <a >
                                    <div class="mask"></div>
                                  </a>
                                </div>
                                <div class="card-body pb-0">
                                  <div class="d-flex justify-content-between">
                                    <div>
                                      <p><a  class="text-dark p-b-10">{{this.productName}}</a></p>
                                      <h6 class="small text-muted p-b-10">{{this.variant_name}}</h6>
                                      <p class="small text-muted p-b-10">{{this.productModelName}}</p>
                                    </div>
                                    <div>
                                      <div class="d-flex flex-row justify-content-end mt-1 mb-4 text-danger">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                      </div>
                                      <p class="small text-muted">Rated 4.0/5</p>
                                    </div>
                                  </div>
                                </div>


                                
                                <hr class="my-0" />
                                <div class="card-body pb-2">
                                  <div class="d-flex justify-content-between">
                                    <p><a  class="text-dark">SL NO :: </a></p>
                                    <p class="text-dark">{{this.productSerialNo}}</p>
                                  </div>
                                  <p class="small text-muted">( Barcode )</p>
                                </div>

                                <hr class="my-0" />


                                <div class="card-body pb-2">
                                  <div class="d-flex justify-content-between">
                                    <p><a  class="text-dark"> Price : </a></p>
                                    <p class="text-dark">{{this.discountPrice}}</p>
                                  </div>
                                  <p class="small text-muted">( INR )</p>
                                </div>

                                <hr class="my-0" />

                                <div class="card-body pb-2">
                                  <div class="d-flex justify-content-between">
                                    <p><a  class="text-dark">Warranty :  </a></p>
                                    <p class="text-dark">{{this.warranty}} Months</p>
                                    
                                  </div>
                                  <p class="small text-muted"> ( In Months )</p>
                                </div>
                                <hr class="my-0" />

                                <div  *ngIf="this.c_warranty">
                                  <div class="card-body pb-2">
                                    <div class="d-flex justify-content-between">
                                      <p><a   class="text-dark">Cartridge  :  </a></p>
                                      <p class="text-dark">{{this.cartridge_name}}</p>
                                      
                                    </div>
                                    <p class="small text-muted"> -- </p>
                                  </div>
                                  <hr class="my-0" />
                                </div>

                                <div  *ngIf="this.c_warranty">
                                  <div class="card-body pb-2">
                                    <div class="d-flex justify-content-between">
                                      <p><a   class="text-dark">Cartridge Warranty :  </a></p>
                                      <p class="text-dark">{{this.c_warranty}} Months</p>
                                      
                                    </div>
                                    <p class="small text-muted"> ( In Months )</p>
                                  </div>
                                  <hr class="my-0" />
                                </div>

                                <div  *ngIf="this.c_life">
                                  <div class="card-body pb-2">
                                    <div class="d-flex justify-content-between">
                                      <p><a  class="text-dark">Cartridge Life :  </a></p>
                                      <p class="text-dark">{{this.c_life}} Months</p>
                                      
                                    </div>
                                    <p class="small text-muted"> ( In Months )</p>
                                  </div>
                                  <hr class="my-0" />
                                </div>


                                <div class="card-body">
                                  <div class="d-flex justify-content-between align-items-center pb-2 mb-1">
                                    <a   class="text-dark fw-bold" (click)="scanCode(true)"  >Cancel</a>
                                    <button type="button" class="btn btn-primary"(click)="registerProduct()"  *ngIf="this.productModelName"  > Confirm Product </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>







                    
                </div>
               

            </div>


    </div>
</section>
</main>
<app-footer></app-footer>