<app-header></app-header>
<main id="main" class="p-t-50">
    <section id="speakers">
       <div class="container" data-aos="fade-up">
            <section class="bg0 p-t-5 p-b-80">
                <div class="container">        
                  <div>
                      <span class="stext-110 cl2">
                          Thank you for purchasing from WaterScience.
                      </span>
                  </div>
            
                  <div>
                    <div>
                      <span class="stext-110 cl2">
                        You will get updates on whatsapp
                      </span>
                    </div>
                  </div>

                  <div>
                    <div class="p-b-35 m-t-20 center full-width" style="display: flex;" >
                      <i class="bi bi-whatsapp m-t-5" style="color: #2cb642" style="color: #2cb642;font-size: 2.5rem;"></i>
                      <button class="flex-c-m stext-101 cl0 size-116 bg-btn-bluegrey bor14 hov-btn-custom p-lr-15 m-l-20 trans-04 pointer"  (click)="sendWhatsApp()"     >
                        WhatsApp These Details
                      </button>
                    </div>                     
                    <div class="bor8 bg0 m-b-12 bg-dustpink">
                      <input class="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" placeholder="Customer Mobile" [(ngModel)]="customerMobile" [disabled]="true">
                    </div>
                  </div>

                  <div class="col-sm-6 col-md-6 col-lg-6 p-b-35 center">
                    <button class="flex-c-m stext-101 cl0 size-116 bg-btn-blue bor14 hov-btn-custom p-lr-15 m-t-50 trans-04 pointer">
                      <a routerLink="/registerproduct" class="black-txt hov-btn-custom">Home</a>
                    </button>
                  </div>
                </div>
            </section>
        </div>
    </section>
  </main>
<app-footer></app-footer>