<app-header></app-header>
<main id="main" class="p-t-50">
    <section id="speakers">
       <div class="container" data-aos="fade-up">
            <section class="bg0 p-t-5 p-b-80">
                <div class="card pxy25">        
                  <div>
                      <span class="stext-110 cl2">
                          You are eligible for the following free combo product ( Pick any one ).
                      </span>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-12 p-b-35 center">
                      <span class="stext-110 cl2"> Please pick your choice </span>    
                  </div>     
            
                  <section id="speakers">
                    <div class="container" data-aos="fade-up">
                      <div class="row" >
                        <div>


                          <div class="col-md-4 col-lg-4 col-sm-12  col-xs-12" *ngFor="let offer of offerProducts"  style="margin-bottom:20px;">
                            <div class="card" style="border-radius: 15px;">
                              <div class="bg-image hover-overlay ripple ripple-surface ripple-surface-light"
                                data-mdb-ripple-color="light">
                                <img src="{{imagePath+'/'+ offer.image}}"  style="border-top-left-radius: 15px; border-top-right-radius: 15px; width: 100%;" class="img-fluid"
                                  alt="Laptop" />
                                <a href="">
                                  <div class="mask"></div>
                                </a>
                              </div>
                              <div class="card-body pb-0">
                                <div class="d-flex justify-content-between">
                                  <div>
                                    <p><a href="" class="text-dark p-b-10">{{offer.complimentary_name}}</a></p>
                                  </div>
                                  <div>
                                    <div class="d-flex flex-row justify-content-end mt-1 mb-4 text-danger">
                                      <i class="fas fa-star"></i>
                                      <i class="fas fa-star"></i>
                                      <i class="fas fa-star"></i>
                                      <i class="fas fa-star"></i>
                                    </div>
                                    <p class="small text-muted">Rated 4.0/5</p>
                                  </div>
                                </div>
                              </div>


                              
                              <hr class="my-0" />
                              <div class="card-body pb-2">
                                <div class="d-flex justify-content-between">
                                  <p><a href="" class="text-dark">Product </a></p>
                                  <p class="text-dark">{{offer.complimentary_name}}</p>
                                </div>
                                <p class="small text-muted">Name</p>
                              </div>

                             
                              <hr class="my-0" />

                              <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center pb-2 mb-1">
                                  
                                  <button type="button" class="btn btn-primary" (click)="selectOfferProduct(offer);saveOfferProduct();" > Add </button>
                                </div>
                              </div>
                            </div>
                          </div>

<!-- 
                          <div class="speaker" data-aos="fade-up" data-aos-delay="200">
                            <img src="{{imagePath+'/'+ offer.image}}" class="img-fluid">
                            <div class="details">
                                <h3><a (click)="selectOfferProduct(offer)">{{offer.complimentary_name}}</a></h3>
                            </div>
                          </div> -->



                        </div>                      
                      </div>
                    </div>
                  </section>					
                                  
                  <!-- <div class="col-sm-6 col-md-6 col-lg-6 p-b-35 center" style="display: flex;">
                    <button class="flex-c-m stext-101 cl0 size-116 bg-btn-blue bor14 hov-btn-custom p-lr-15 m-t-50 trans-04 pointer">
                      <a (click)="saveOfferProduct()" class="black-txt hov-btn-custom">CONTINUE</a>
                    </button>
                    <button class="flex-c-m stext-101 cl0 size-116 bor14 hov-btn-custom p-lr-15 m-t-50 trans-04 pointer bg-dustpink">
                      <a routerLink="/confirmpurchase" class="black-txt hov-btn-custom">SKIP</a>
                    </button>
                  </div> -->


                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center pb-2 mb-1">
                      <a routerLink="/confirmpurchase" class="text-dark fw-bold"  > Skip </a>
                    </div>
                  </div>
                
                </div>
            </section>
      </div>
    </section>
  </main>
<app-footer></app-footer>