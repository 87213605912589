import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
   globalcompanyData = { companyId: ''};
   sessionproductData = { productId: '', productName: '', serialNo: '',
                          modelNo: '', variant: '',variant_id:'',variant_name:'', 
                          price: '', discountedPrice: '',
                          rewardPoint: '', warrantyMonths: '',
                          image: '',
                          warrantyDate: '',
                          purchaseDate: '',    
                           cartridge_name: '',   
                          cartridge_id: '',    
                          c_warranty: '',   
                          c_life: '',
                          c_warrantyDate: '',   
                          c_lifeDate: ''
                        };
   sessioncustomerData = { customerName: '', customerMobile: ''};
   sessionOfferProductData = { accessory_id: '', accessory_name: ''};   
   sessionPurchaseData = { purchase_id: '', offerAvailable: false};
   sessionAccessoryData =[];
};


