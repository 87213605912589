import { Component, OnInit, AfterViewInit  } from '@angular/core';

//------------------- Add on imports ----------------------
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedDataService } from '../shared-data.service';
import { NotificationService } from '../notification.service';
import { BarcodeFormat } from '@zxing/library';

@Component({
  selector: 'app-register-product',
  templateUrl: './register-product.component.html',
  styleUrls: ['./register-product.component.css']
})
export class RegisterProductComponent implements OnInit {
  constructor( 
    public apiService:ApiService, 
    private dataService: SharedDataService,
    private router: Router,
    private nofifyservice: NotificationService
  ){}

  data: any;
  productDetailsResponse: any;
  productDetails = {
     product_id: '', 
     product_name: '', 
     serial_number: '',
     model_number: '', 
     variant: '', 
     variant_name: '', 
     variant_id: '', 
     price: '', 
     discount_price: '',
     point: '', 
     warranty: '',
     image: '',
     warrantyDate: '',
     purchaseDate: '',     
     cartridge_name: '',   
     cartridge_id: '',    
     c_warranty: '',   
     c_life: '',
     c_warrantyDate: '',   
     c_lifeDate: ''
    };
  productSerialNo: any;
  productName: any;
  productModel: any;
  productModelName: any;
  variant: any;
  variant_id: any;
  variant_name: any;
  discountPrice: any;
  warranty: any;
  cartridge_name:any;
  cartridge_id:any;
  c_warranty: any;
  c_life:any;
  c_warrantyDate: any;
  c_lifeDate:any;
  scanButton: boolean=false;
  
   ngOnInit() {     

  }

  clearProductData(){
    this.data="";
    this.productDetailsResponse="";
    this.productDetails = {
     product_id: '', 
     product_name: '', 
     serial_number: '',
     model_number: '', 
     variant: '', 
     variant_name: '', 
     variant_id: '', 
     price: '', 
     discount_price: '',
     point: '', 
     warranty: '',
     image: '',
     warrantyDate: '',
     purchaseDate: '',
     cartridge_name: '',   
     cartridge_id: '',    
     c_warranty: '',   
     c_life: '',
     c_warrantyDate: '',   
     c_lifeDate: ''
    };
    this.productSerialNo="";
    this.productName="";
    this.productModel="";
    this.productModelName="";
    this.variant="";
    this.variant_id="";
    this.variant_name="";
    this.discountPrice="";
    this.warranty="";
    this.cartridge_name ="";        
    this.cartridge_id = "";        
    this.c_warranty ="";        
    this.c_life = ""; 
    this.c_warrantyDate ="";        
    this.c_lifeDate = ""; 

    
  }
  setSharedProductData() {
    this.dataService.sessionproductData = { 
      productId: this.productDetailsResponse.product_id, 
      productName: this.productDetailsResponse.product_name, 
      serialNo: this.productDetailsResponse.serial_num,
      modelNo: this.productDetailsResponse.model_number, 
      variant: this.productDetailsResponse.variant, 
      variant_id: this.productDetailsResponse.variant_id, 
      variant_name: this.productDetailsResponse.variant_name, 
      price: this.productDetailsResponse.price, 
      discountedPrice: this.productDetailsResponse.discount_price,
      rewardPoint: this.productDetailsResponse.point, 
      warrantyMonths: this.productDetailsResponse.warranty,
      cartridge_name :this.productDetailsResponse.cartridge_name,      
      cartridge_id : this.productDetailsResponse.cartridge_id,        
      c_warranty : this.productDetailsResponse.c_warranty,     
      c_life : this.productDetailsResponse.c_life,
      c_warrantyDate : '',     
      c_lifeDate : '',
      image: '',
      warrantyDate : '',
      purchaseDate: ''
    };
  }
  getProductDetailsbySlNo() { 
           
    this.data = '{' + '"serial_num":' + '"' + this.productSerialNo + '"' + '}'; 
    this.apiService.postRequest('/getProductBySerialNo', this.data).subscribe((res: any) => {
      if (typeof res === 'object') {
        this.clearProductData();
        if (res.msg === "Success") {
          this.scanCode(false);
          this.showScanner = false;

          this.productDetailsResponse = res.data[0];
          this.productName = this.productDetailsResponse.product_name;
          this.productModel = this.productDetailsResponse.model_id;
          this.productModelName = this.productDetailsResponse.model_number;
          this.variant = this.productDetailsResponse.variant_id;
          this.variant_id = this.productDetailsResponse.variant_id;
          this.variant_name = this.productDetailsResponse.variant;
          this.discountPrice = this.productDetailsResponse.discount_price
          this.productSerialNo = this.productDetailsResponse.serial_num;        
          this.warranty = this.productDetailsResponse.warranty;        
          this.cartridge_name = this.productDetailsResponse.cartridge_name;        
          this.cartridge_id = this.productDetailsResponse.cartridge_id;        
          this.c_warranty = this.productDetailsResponse.c_warranty;        
          this.c_life = this.productDetailsResponse.c_life;        
        }
        else {
          this.nofifyservice.showWarning(res.msg,"Sorry!");
        }
      }
    });    
  }
  registerProduct() {
    if (this.productSerialNo === "" || this.productSerialNo === undefined) {
       this.nofifyservice.showWarning ('Please scan a product to proceed','');
    }
    else {
      this.setSharedProductData();
      this.router.navigate(["/registercustomer"]);
    }
  }
  //----------------- bar code, qr code scanner -------------------
  allowedFormats = [
    BarcodeFormat.CODE_128
  ];

  clearResult(): void {
    this.productSerialNo = '';
    this.startScan = true;
    this.showScanner = true;

    this.productDetailsResponse = '';
    this.productName ='';
    this.productModel = '';
    this.productModelName = '';
    this.variant = '';
    this.variant_id = '';
    this.variant_name ='';
    this.discountPrice = '';       
    this.warranty = ''; 
  }

  onCodeResult(resultString: string) {
    this.nofifyservice.showInfo("Fetching product details,Please wait.","");
    this.productSerialNo= "";
    if (resultString !== null) {
      this.productSerialNo = resultString;
      try {
        this.getProductDetailsbySlNo();
        this.scanCode(false);
        this.showScanner = false;

      } catch (e) {
          this.nofifyservice.showInfo("Unable to get product details","");
          this.clearProductData();
      }
    }
  }

  // scan button toggle
  startScan: boolean = true;
  showScanner: boolean = true;
  scanCode(action: boolean) {
    if (action) {
      this.showScanner = true;
    } else {
      this.showScanner = false;
    }
    
  }
}