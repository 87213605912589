import { Component, OnInit } from '@angular/core';

//------------------- Add on imports ----------------------
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedDataService } from '../shared-data.service';
import { NotificationService } from '../notification.service';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';

@Component({
  selector: 'app-offer-product',
  templateUrl: './offer-product.component.html',
  styleUrls: ['./offer-product.component.css']
})
export class OfferProductComponent implements OnInit{
  constructor( 
    public apiService:ApiService, 
    private dataService: SharedDataService,
    private router: Router,
    private nofifyservice: NotificationService
  ){}

  offerProducts: any;
  offerProductId: any;
  offerProductName: any;
  imagePath: any;

  ngOnInit(){
    this.getComplimentaryProducts();
  }
  productData: any;

  selectOfferProduct(offer: any) {
    this.offerProductId = offer.complimentary_id; 
    this.offerProductName = offer.complimentary_name;
  }
  getComplimentaryProducts(){ 
    this.productData = '{' + '"product_id":' + '"' +  this.dataService.sessionproductData.productId + '","serial_num":' + '"' +  this.dataService.sessionproductData.serialNo  + '"}';
    this.apiService.postRequest('/getComplimentaryProducts', this.productData).subscribe((res:any) => {
      if (typeof res === 'object') {
        if (res.msg === "Success") {
          console.log ("On offer products......");
          this.offerProducts = res.data;
          this.imagePath = res.image_path;
        }
        else {
          this.nofifyservice.showWarning("Unable to check offer products","");
          this.router.navigate(["/confirmpurchase"]);
        }
      }
    });  
  }

  setSharedOfferData() {
    this.dataService.sessionOfferProductData = { 
      accessory_id: this.offerProductId,
      accessory_name: this.offerProductName
    };
  }
  saveOfferProduct() {
    if (this.offerProductId === "" || this.offerProductId === undefined) {
      this.nofifyservice.showWarning ('Please click on an Offer to select','');
    }
    else {
      this.setSharedOfferData();
      this.router.navigate(["/customerdetails"]);  
    }
  }
}
