<header id="header" class="d-flex align-items-center">
    <div class="container-fluid container-xxl d-flex align-items-center">
       <div id="logo" class="me-auto"> <a  class="scrollto"><img src="assets/img/WS_logo_with_Tagline.png" alt="" title=""></a></div>
       <nav id="navbar" class="navbar order-last order-lg-0">
          <ul>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
       </nav>
    </div>
</header>
