import { Component } from '@angular/core';

//------------------- Add on imports ----------------------
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedDataService } from '../shared-data.service';
import { NotificationService } from '../notification.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.css']
})
export class ConfirmRegistrationComponent {

  constructor( 
    public apiService:ApiService, 
    private dataService: SharedDataService,
    private router: Router,
    private nofifyservice: NotificationService
    ) { }
  offerProducts: any;
  offerProductsList: boolean = false;
  productData: any;
  warrantyDate: any;
  replacementDate: any;
  offersAvailable: boolean = true;
  accessoryData: any;
  customerName: any;
  purchaseID: any;
  formData: any;
  c_warranty: any;
  c_life:any;
  c_warrantyDate: any;
  c_lifeDate:any;

  myDate = new Date();  
	  
  ngOnInit() {
      this.customerName = this.dataService.sessioncustomerData.customerName;
      this.warrantyDate =  this.dataService.sessionproductData.warrantyDate;
      this.replacementDate = this.dataService.sessionproductData.warrantyDate;
      this.purchaseID = this.dataService.sessionPurchaseData.purchase_id;
      this.c_warranty = this.dataService.sessionproductData.c_warranty;
      this.c_life = this.dataService.sessionproductData.c_life;
      this.c_warrantyDate = this.dataService.sessionproductData.c_warrantyDate;
      this.c_lifeDate = this.dataService.sessionproductData.c_lifeDate;
      this.sendWhatsApp();
      this.offersAvailable = false;
      this.getComplimentaryProducts();
   }
   // check complimentary products exists then show Take Me to Offers or take to Thank you screen
   getComplimentaryProducts(){ 
    this.productData = '{' + '"product_id":' + '"' +  this.dataService.sessionproductData.productId + '","serial_num":' + '"' +  this.dataService.sessionproductData.serialNo  + '"}';
    this.apiService.postRequest('/getComplimentaryProducts', this.productData).subscribe((res:any) => {
      if (typeof res === 'object') {
        if (res.msg === "Success") {
          this.offerProducts = res.data;
          if(res.data.length>0){this.offerProductsList=true;}else{this.offerProductsList=false;}

        }
        else {
          this.offerProductsList=false;
        }
      }
    });  
  }

   sendWhatsApp(){
     
    console.log ("Sending whatsapp message...");
 
    this.formData = '{' + '"purchase_id":' + '"' +  this.purchaseID  + '"}';
    // this.formData = '{' + '"purchase_id":' + '"1"}';
    this.apiService.postRequest('/sendWhatsappMessage', this.formData).subscribe((res: any) => {
      if (typeof res === 'object') {
        if (res.statusText==="Accepted") {            
            console.log ('Purchase details sent to registered WhatsApp number  successfully');
            this.nofifyservice.showSuccess ("Purchase details sent to registerd WhatsApp number.","");
        } 
        else {
          this.nofifyservice.showWarning ("Unable to send details via WhatsApp.","");
          return;
        }      
      }            
    }); 
  }  
    
}

