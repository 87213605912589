<app-header></app-header>
<main id="main" class="p-t-50">
    <section id="speakers">
       <div class="container" data-aos="fade-up">
            <section class="bg0 p-t-5 p-b-35">
                <div class="container">        
                <div class="card pxy25">        
                    <div>
                        <span class="stext-110 cl2">
                            Thank you, {{customerName}}
                        </span>
                    </div>
            
					<div>
						<div>
							<span class="stext-110 cl2">
								Your product is covered under warranty till
							</span>
						</div>
						<div class="bor8 bg0 m-b-12 bg-dustpink">
							<input class="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" [(ngModel)]="warrantyDate" [disabled]="true">
						</div>
					</div>

					<section class="bg0 p-t-5 p-b-35" >
						<div class="container">
							<div class="row isotope-grid" *ngFor="let accessory of accessoryData">
								<div>
									<span class="stext-110 cl2">
										The {{accessory.accessory_name}} will need to be replace before
									</span>
								</div>
								<div class="bor8 bg0 m-b-12 bg-dustpink">
									<input class="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" [(ngModel)]="accessory.warrantyDate" [disabled]="true">
								</div>		
							</div>
						</div>
					</section>		

					
					<section class="bg0 " *ngIf="this.c_warranty">
						<div class="container">
							<div class="row isotope-grid">
								<div>
									<span class="stext-110 cl2">
										Your cartridge is covered under warranty till
									</span>
								</div>
								<div class="bor8 bg0 m-b-12 bg-dustpink">
									<input class="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" [(ngModel)]="c_warrantyDate" [disabled]="true">
								</div>		
							</div>
						</div>
					</section>		



					<section class="bg0 p-t-5 p-b-35" *ngIf="this.c_life">
						<div class="container">
							<div class="row isotope-grid" >
								<div>
									<span class="stext-110 cl2">
										Your cartridge will need to be replace before
									</span>
								</div>
								<div class="bor8 bg0 m-b-12 bg-dustpink">
									<input class="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" [(ngModel)]="c_lifeDate" [disabled]="true">
								</div>		
							</div>
						</div>
					</section>					


                    <!-- div class="container" style="display: flext;">
						<i class="bi bi-whatsapp" style="color: #2cb642"></i>
						<div class="bor8 bg0 m-b-12 bg-btn-bluegrey">							
							<input class="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" placeholder="WhatsApp These Details" [disabled]="true">
						</div>
                    </div -->
                    <div class="p-b-35 center full-width" style="display: flex;" >
						<i class="bi bi-whatsapp m-t-5" style="color: #2cb642" style="color: #2cb642;font-size: 2.5rem;"></i>
						<button class="flex-c-m stext-101 cl0 size-116 bg-btn-bluegrey bor14 hov-btn-custom p-lr-15 m-l-20 trans-04 pointer" (click)="sendWhatsApp()" >
						  WhatsApp These Details
						</button>
					</div>           
                    <div  *ngIf="this.offerProductsList">
                        <span class="stext-110 cl2">
                            You are eligible for a special offer
                        </span>
                    </div>

					<div class="col-sm-6 col-md-6 col-lg-6 p-b-35 center"  *ngIf="this.offerProductsList" >
						<button class="flex-c-m stext-101 cl0 size-116 bg-btn-lemon bor14 hov-btn-custom p-lr-15 m-t-50 trans-04 pointer">
							<a routerLink="/offerproduct" class="black-txt hov-btn-custom">Take Me To Offers</a>
						</button>
					</div>
					<div class="col-sm-6 col-md-6 col-lg-6 p-b-35 center" *ngIf="!this.offerProductsList" >
						<button class="flex-c-m stext-101 cl0 size-116 bg-btn-blue bor14 hov-btn-custom p-lr-15 m-t-50 trans-04 pointer">
						  <a routerLink="/registerproduct" class="black-txt hov-btn-custom">Take Me To Home</a>
						</button>
					  </div>
                </div>
                </div>
            </section>
        </div>
    </section>
  </main>
<app-footer></app-footer>