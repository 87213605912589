import { Injectable } from '@angular/core';

//------------------- Add on imports ---------------------------
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  httpOptions: any;
  baseUrl = 'https://admin.solsten.in/Mobile';
  constructor(public http: HttpClient) { }
  /*
  post(url:any, data:any, callback:any) {
    let headers = new Headers();
    if (url !== "/uploadInvoice") {
      this.httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
      }      
    }
    else {
      headers.append('enctype', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      this.httpOptions = { headers: headers};
    }

    this.http.post(this.baseUrl + url, data, this.httpOptions).subscribe(res => {
      callback(res);
    });
  }
  */
  get(url:any, callback:any) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  
    this.http.get(this.baseUrl + url, this.httpOptions).subscribe(res => {
      callback(res);
    });
  }
  postRequest(url:any, data:any): Observable<any>{
    let headers = new Headers();
    if (url !== "/uploadInvoice") {
      this.httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
      }      
    }
    else {
      headers.append('enctype', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      this.httpOptions = { headers: headers};
    }
    return this.http.post(this.baseUrl + url, data);
  }
  
}
