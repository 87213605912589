import { Component, OnInit, LOCALE_ID, ViewChild  } from '@angular/core';

//------------------- Add on imports ----------------------
import { Event, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedDataService } from '../shared-data.service';
import { formatDate } from '@angular/common';
import { NotificationService } from '../notification.service';
import { HttpClient } from  '@angular/common/http';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { NgxRandomService } from "ngx-random";

@Component({
  selector: 'app-register-customer',
  templateUrl: './register-customer.component.html',
  styleUrls: ['./register-customer.component.css']
})
export class RegisterCustomerComponent implements OnInit {  

  constructor(
    public apiService:ApiService,
    private dataService: SharedDataService,
    private router: Router,
    private nofifyservice: NotificationService,
    private http: HttpClient,
    private ngxRandom: NgxRandomService
  ) {}
  
  addPurchaseData: any;
  responseData: any;
  //purchaseId: any;
  customerName: any;
  customerMobile: any;
  showConfirm: boolean = false;
  fileSelectedStatus: boolean = false;
  selectedFileName: any;

  myDate = new Date();  
  myDate1 = new Date();  
  myDate2 = new Date();   
  invoiceDate: any;

  validInputs: boolean = false;
  responsePurchaseData: any;

  file: File | any = null;
 
  onChange(event: any) {
    this.selectedFileName ="No file selected";
    this.file = event.target.files[0];
    this.selectedFileName = this.file.name;
  }

  onUpload() {
    if (this.file) {
      //this.loading = !this.loading;
      console.log(this.file);
      const formData = new FormData();
  
      // Store form name as "file" with file data
      formData.append('file_name', this.file, this.file.name);
      formData.append('purchase_id',this.responsePurchaseData.purchase_id);   
  
      let url = "/uploadInvoice";
  
      this.apiService.postRequest('/uploadInvoice', formData).subscribe((res: any) => {
        if (typeof res === 'object') {
          if (res.msg === "Success") {            
            console.log ('File posted good');
          }
          else  { alert ('file upload not good');}

        }
      });    
    }
  }
 
  ngOnInit() {
    this.showConfirm = false;
    this.selectedFileName = "No file selected";
    this.fileSelectedStatus = false;
    this.invoiceDate = formatDate(this.myDate, 'dd-MM-yyyy' , 'en-US');
    this.dataService.sessionproductData.purchaseDate = this.invoiceDate;
    
    var newDate = new Date(this.myDate.setMonth(this.myDate.getMonth()+ Number(this.dataService.sessionproductData.warrantyMonths)));
    this.dataService.sessionproductData.warrantyDate = formatDate(newDate, 'dd-MM-yyyy' , 'en-US');

    if(this.dataService.sessionproductData.c_warranty){
        var newDate1 = new Date(this.myDate1.setMonth(this.myDate1.getMonth()+ Number(this.dataService.sessionproductData.c_warranty)));
        this.dataService.sessionproductData.c_warrantyDate = formatDate(newDate1, 'dd-MM-yyyy' , 'en-US');

     } 
    if(this.dataService.sessionproductData.c_life){
      var newDate2 = new Date(this.myDate2.setMonth(this.myDate2.getMonth()+ Number(this.dataService.sessionproductData.c_life)));
      this.dataService.sessionproductData.c_lifeDate = formatDate(newDate2, 'dd-MM-yyyy' , 'en-US');
    } 
    
    //alert ("invoice / warranty dt " + this.dataService.sessionproductData.purchaseDate + " / " + this.dataService.sessionproductData.warrantyDate);
    this.validInputs= false;
  }
  
  submitPurchase(){
    if (this.verifyOtp()) {
      this.storeCustomerData();
      this.addPurchase();
    }
  }

  storeCustomerData() {
    this.dataService.sessioncustomerData = { 
      customerName: this.customerName, 
      customerMobile: this.customerMobile
    };
  }
  setSharedPurchaseData() {
    this.dataService.sessionPurchaseData = { 
      purchase_id: this.responsePurchaseData.purchase_id,
      offerAvailable: this.responsePurchaseData.offer_available
    };
    this.dataService.sessionAccessoryData = this.responsePurchaseData.accessories;
  }

  sp:any=[];
  validateInputs () {
    this.sp = this.selectedFileName.split(".");
    //get the part AFTER the LAST period.
    var fileType = "." + this.sp[this.sp.length-1];

    if (this.customerName === "" || this.customerName === undefined) {
      this.nofifyservice.showWarning ('Please enter Cutsomer Name','');
      this.validInputs= false;
      return;
    }
    if (this.customerMobile === "" || this.customerMobile === undefined) {
      this.nofifyservice.showWarning ('Please enter Cutsomer Mobile','');
      this.validInputs= false;
      return;
    }
    if (this.customerMobile.length !== 10) {
      this.nofifyservice.showWarning ('Please enter a 10 digit mobile number','');
      this.validInputs= false;  
      return;
    }    
    if (this.selectedFileName === "" || this.selectedFileName === "No file selected") {
      this.nofifyservice.showWarning ('Please upload Invoice file','');
      this.validInputs= false;
      return;
    }
    if (fileType === ".pdf" || fileType === ".jpg" || fileType === ".png" || fileType === ".jpeg") {

    }
    else{
      this.nofifyservice.showWarning ('Please upload a valid pdf or image file','');
      this.validInputs= false;  
      return;
    }    
    this.validInputs= true;    
  }
  addPurchase(){      
    this.addPurchaseData = '{' + '"company_id":' + '"' +  sessionStorage.getItem('companyId') + '",' 
                              + '"customer_mobile":' + '"' + this.customerMobile + '",' 
                              + '"customer_name":' + '"' + this.customerName + '",' 
                              + '"offer_id":' + '"' + 0 + '",' 
                              + '"offer_product_id":' + '"' + 0 + '",' 
                              + '"point":' + '"' + this.dataService.sessionproductData.rewardPoint + '",' 
                              + '"product_id":' + '"' + this.dataService.sessionproductData.productId + '",' 
                              + '"product_warranty_expiry":' + '"' + this.dataService.sessionproductData.warrantyDate + '",' 
                              + '"purchase_date":' + '"' + this.dataService.sessionproductData.purchaseDate + '",' 
                              + '"purchase_price":' + '"' + this.dataService.sessionproductData.discountedPrice + '",' 
                              + '"serial_number":' + '"' + this.dataService.sessionproductData.serialNo + '"' +
                            '}';
    this.apiService.postRequest('/addPurchase', this.addPurchaseData).subscribe((res: any) => {
      if (typeof res === 'object') {
        if (res.msg === "Success") {            
          this.responsePurchaseData = res;
          this.setSharedPurchaseData();
          this.onUpload();
          this.nofifyservice.showSuccess ("Invoice uploaded successfully","");
          this.sendSMS();
          this.router.navigate(["/confirmregistration"]);        
        } 
        else {
          this.nofifyservice.showWarning ("Unable to upload Invoice","");
          return;
        }      
      }            
    });        
}
  // random number
  randomNumber: any;

  // OTP
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6, 
    autofocus: true,
    numericInputMode:true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };

  enteredOtp: any;
  handeOtpChange(value: string[]): void {
    console.log(value);
  }

  handleFillEvent(value: string): void {
    console.log("complete - " +value);
    this.enteredOtp = value;
  }

  otpData: any;
  otp: string = '';
  getOTP(){
    this.validateInputs();
    if (this.validInputs) {
      //this.otp = "562391";    
      this.randomNumber = this.ngxRandom.generateNumber(6);
      this.otp = this.randomNumber.toString();
      this.showConfirm = true;    
      this.otpData = '{' + '"mobile":' + '"' +  this.customerMobile + '",' 
                           + '"otp":' + '"' + this.otp + '"'  
                           + '}';
      this.apiService.postRequest('/sendOtp', this.otpData).subscribe((res: any) => {
          if (typeof res === 'object') {
            if (res.SMSMessage.Body !== "") {            
                this.showConfirm = true;
            } 
            else {
              this.nofifyservice.showWarning ("Unable to send Otp","");
              return;
            }      
          }            
      }); 
   }
  }  

  verifyOtp()  : boolean {
    if (this.otp === this.enteredOtp) {
      return true;
    }
    else { 
      this.nofifyservice.showWarning ("Please verify the OTP","");
      return false };
  }


  smsData: any;
  sendSMS(){
     
   //alert ("invoice / warranty dt " + this.dataService.sessionproductData.purchaseDate + " / " + this.dataService.sessionproductData.warrantyDate);
   //var newDate = new Date(this.dataService.sessionproductData.warrantyDate);
   //var newDate = new Date();
   //var newDate = formatDate(this.dataService.sessionproductData.warrantyDate, 'dd-MM-yyyy' , 'en-US');
   var newDate = this.dataService.sessionproductData.warrantyDate;
  // var dtMMMFprmat = formatDate(newDate, 'dd MMM yyyy' , 'en-US');

   this.smsData = '{' + '"mobile":' + '"' +  this.dataService.sessioncustomerData.customerMobile + '",' 
                           + '"purchase_success":' + '"' + 1 + '",'
                           + '"warranty_date":' +   '"' + newDate + '"'
                           + '}';
   this.apiService.postRequest('/sendOtp', this.smsData).subscribe((res: any) => {
     if (typeof res === 'object') {
       if (res.SMSMessage.Body !== "") {            
           console.log ('SMS sent successfully');
       } 
       else {
         this.nofifyservice.showWarning ("Unable to send Otp","");
         return;
       }      
     }            
   }); 
 }  
}



