import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { RegisterProductComponent } from './register-product/register-product.component';
import { RegisterCustomerComponent } from './register-customer/register-customer.component';
import { ConfirmRegistrationComponent } from './confirm-registration/confirm-registration.component';
import { OfferProductComponent } from './offer-product/offer-product.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { ConfirmPurchaseComponent } from './confirm-purchase/confirm-purchase.component';

const routes: Routes = [ 
  { path: '#', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'dealer/:did', component: HomeComponent },
  { path: 'registerproduct', component: RegisterProductComponent },    
  { path: 'registercustomer', component: RegisterCustomerComponent },        
  { path: 'confirmregistration', component: ConfirmRegistrationComponent },            
  { path: 'offerproduct', component: OfferProductComponent },                
  { path: 'customerdetails', component: CustomerDetailsComponent },                
  { path: 'confirmpurchase', component: ConfirmPurchaseComponent },    
  { path: '**', component: HomeComponent },                    
  { path: '', pathMatch: 'full', redirectTo: 'home'}
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
