<app-header></app-header>



<section id="hero" *ngIf="this.scannerview">


    <div class="hero-container" data-aos="zoom-in" data-aos-delay="100">
			<div class="container py-5">
			  <div class="row justify-content-center">
				<div class="col-md-8 col-lg-6 col-xl-4">
				  <div class="card" style="border-radius: 5px;">
					<div class="bg-image hover-overlay ripple ripple-surface ripple-surface-light" data-mdb-ripple-color="light" id="image_holder">
					  <img src="assets/img/wsscanner.gif"
					  	style="border-top-left-radius: 15px; border-top-right-radius: 15px;" class="img-fluid"
					  	alt="wsscanner" />
						<a href="#!">
					  		<div class="mask"></div>
						</a>
				  	</div>

					<div class="bg-image hover-overlay ripple ripple-surface ripple-surface-light" data-mdb-ripple-color="light" id="video_holder" style="display:none">
					  <video id="video" width="350" height="300" class="img-fluid"
						alt="QR SCANNER" style="padding: 3%;"></video>
					  <a href="#!">
						<div class="mask"></div>
					  </a>
					</div>

					<hr class="my-0" />


					<div class="card-body">
						<div id="sourceSelectPanel" style="display:none">
							<div class="mb-3 row">
							<div class="col-md-2"></div>
							<div class="col-md-8">
								<label for="videosource" class="col-form-label">Select Camera </label>
								<select id="sourceSelect" class="form-select form-select mb-3" style="max-width:400px"> </select>
							</div>
							<div class="col-md-2"></div>
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="d-flex justify-content-between align-items-center pb-2 mb-1">
						  <a href="" class="text-dark fw-bold"  id="resetButton" >Cancel</a>
						  <button type="button" class="btn btn-primary" id="startButton" >Scan Now </button>
						</div>

						<div style="display:none">
							<label for="decoding-style"> Decoding Style:</label>
							<select id="decoding-style" size="1">
							  <option value="once">Decode once</option>
							  <option value="continuously">Decode continuously</option>
							</select>
						  </div>
						  <pre><code id="result"></code></pre>


					</div>
					
	
				  </div>
				</div>
			  </div>
			</div>

	

	

	



    </div>




  </section>
  <main id="main" *ngIf="!this.scannerview" >
    <!-- section id="speakers">
       <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-4 col-md-6">
                <h3><a routerLink="/registerproduct">Register Product</a></h3>
             </div>
             <div class="col-lg-4 col-md-6">
                <h3><a href="#">Marketing Material</a></h3>
             </div>
             <div class="col-lg-4 col-md-6">
                <h3><a href="#">Order Stock</a></h3>
             </div>
             <div class="col-lg-4 col-md-6">
                <h3><a href="#"> "Direct 2 Customer</a></h3>
             </div>
          </div>
       </div>
    </section -->
    <div class="sec-banner bg0 p-t-50 p-b-10">
		<div class="container" *ngIf="this.companyName" >	
			<div class="p-t-15 p-b-10">
				<h3 class="welcome-user-text">
					Welcome, You're here at {{this.companyName}} Store.
				</h3>
				<div class="row" *ngIf="this.companyName">
					<div class="col-sm-3">
						<div class="card">
						  <div class="card-body">
							  <p class="card-text text-muted caption_name">Dealer  :</p>
							<h5 class="card-title">{{this.companyName}}</h5>
							<p *ngIf="this.companyEmail"  class="card-text caption_email">{{this.companyEmail}}</p>
							<p   *ngIf="this.companyGstn" class="card-text caption_email">GSTUIN : {{this.companyGstn}}</p>
							<a   *ngIf="this.companyPh" href="tel:{{this.companyPh}}" class="btn btn-primary  btn-xs caption_phone">{{this.companyPh}}</a>
						  </div>
						</div>
					  </div>
					<div class="col-sm-3 d-none">
					  <div class="card">
						<div class="card-body">
							<p class="card-text text-muted caption_name">Contact  :</p>
						  <h5 class="card-title">{{this.companyOwner}}</h5>
						  <p *ngIf="this.companyEmail"  class="card-text caption_email">{{this.companyEmail}}</p>
						  <a *ngIf="this.companyPh"  href="tel:{{this.companyPh}}" class="btn btn-primary  btn-xs caption_phone">{{this.companyPh}}</a>
						</div>
					  </div>
					</div>
					<div class="col-sm-3 d-none">
					  <div class="card">
						<div class="card-body">
						 <p class="card-text text-muted caption_name">Account Manager :</p>
						  <h5 class="card-title">{{this.accountManager}}</h5>
						  
						  <p class="card-text caption_email" *ngIf="this.accountManagerEmail" >{{this.accountManagerEmail}}</p>
						  <a *ngIf="this.accountManagerPh"  href="tel:{{this.accountManagerPh}}" class="btn btn-xs btn-primary caption_phone">{{this.accountManagerPh}}</a>
						</div>
					  </div>
					</div>
					<div class="col-sm-3  d-none">
						<div class="card">
						  <div class="card-body">
						   <p class="card-text text-muted caption_name">Regional Manager :</p>
							<h5 class="card-title">{{this.regionalManager}}</h5>
							<p *ngIf="this.regionalManagerEmail" class="card-text caption_email">{{this.regionalManagerEmail}}</p>
							<a *ngIf="this.regionalManagerPh" href="tel:{{this.regionalManagerPh}}" class="btn  btn-xs btn-primary caption_phone">{{this.regionalManagerPh}}</a>
						  </div>
						</div>
					</div>
				  </div>			
			</div>	
		</div>
	</div>

	<!-- Product -->
	<section class="bg0 p-t-5 p-b-80">
		<div class="container">

			<div class="grid">
				<div class="row">
					<a routerLink="/registerproduct" class="grid-item" *ngIf="this.companyName" style="background-image: url(assets/img/images/teacher-bg.jpg);">
						<div class="inner">
							<img src="assets/img/images/programming.png" alt="">
							<span>Register Your Product </span>
						</div>
					</a>
					<a href="" class="grid-item d-none" style="background-image: url('assets/img/images/research-bg.jpg');">
						<div class="inner">
							<img src="assets/img/images/research.png" alt="">
							<span>Marketing Material</span>
						</div>
						
					</a>
					<a href="" class="grid-item d-none" style="background-image: url('assets/img/images/programming-bg.jpg');">
						<div class="inner">
							<img src="assets/img/images/teacher.png" alt="">
							<span>Order Stock</span>
						</div>
						
					</a>
					<a href="" class="grid-item d-none" style="background-image: url('assets/img/images/business-bg.jpg');">
						<div class="inner">
							<img src="assets/img/images/business.png" alt="">
							<span>Direct 2 Customer</span>
						</div>
					</a>					
				</div>
				<div class="row d-none">
					<a href="" class="grid-item" style="background-image: url('assets/img/images/tour-guide-bg.jpg');">
						<div class="inner">
							<img src="assets/img/images/tour-guide.png" alt="">
							<span>Offers & Schemes</span>
						</div>
					</a>
					<a href="" class="grid-item d-none" style="background-image: url('assets/img/images/business-bg.jpg');">
						<div class="inner">
							<img src="assets/img/images/business.png" alt="">
							<span>Service/Installation</span>
						</div>
					</a>
					<a href="" class="grid-item d-none" style="background-image: url('assets/img/images/artist-bg.jpg');">
						<div class="inner">
							<img src="assets/img/images/artist.png" alt="">
							<span>Training Center</span>
						</div>
					</a>
					<a href="" class="grid-item d-none" style="background-image: url(assets/img/images/programming-bg.jpg);">
						<div class="inner">
							<img src="assets/img/images/programming.png" alt="">
							<span>My Orders</span>
						</div>
					</a>
				</div>
			</div>

			<div class="row isotope-grid d-none">
				<div class="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item m-b-30">
					<!-- Block2 -->
					<div class="block2">
						<div class="block2-pic">					
							<a routerLink="/registerproduct" class="block2-btn flex-c-m mtext-btn cl2 size-116 bg-btn-blue bor2 hov-btn-custom p-lr-15 trans-04">
								Register Product (New )
							</a>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item m-b-30">
					<!-- Block2 -->
					<div class="block2">
						<div class="block2-pic">					
							<a href="#" class="block2-btn flex-c-m mtext-btn cl2 size-116 bg-btn-blue bor2 hov-btn-custom p-lr-15 trans-04">
								Marketing Material
							</a>
						</div>
					</div>
				</div>

				<div class="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item m-b-30">
					<!-- Block2 -->
					<div class="block2">
						<div class="block2-pic">					
							<a href="#" class="block2-btn flex-c-m mtext-btn cl2 size-116 bg-btn-blue bor2 hov-btn-custom p-lr-15 trans-04">
								Order Stock
							</a>
						</div>
					</div>
				</div>

				<div class="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item m-b-30">
					<!-- Block2 -->
					<div class="block2">
						<div class="block2-pic">					
							<a href="#" class="block2-btn flex-c-m mtext-btn cl2 size-116 bg-btn-blue bor2 hov-btn-custom p-lr-15 trans-04">
								Direct 2 Customer
							</a>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item m-b-30">
					<!-- Block2 -->
					<div class="block2">
						<div class="block2-pic">					
							<a href="#" class="block2-btn flex-c-m mtext-btn cl2 size-116 bg-btn-blue bor2 hov-btn-custom p-lr-15 trans-04">
								Offers & Schemes
							</a>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item m-b-30">
					<!-- Block2 -->
					<div class="block2">
						<div class="block2-pic">					
							<a href="#" class="block2-btn flex-c-m mtext-btn cl2 size-116 bg-btn-blue bor2 hov-btn-custom p-lr-15 trans-04">
								Service/Installation
							</a>
						</div>
					</div>
				</div>

				<div class="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item m-b-30">
					<!-- Block2 -->
					<div class="block2">
						<div class="block2-pic">					
							<a href="#" class="block2-btn flex-c-m mtext-btn cl2 size-116 bg-btn-blue bor2 hov-btn-custom p-lr-15 trans-04">
								Training Center
							</a>
						</div>
					</div>
				</div>

				<div class="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item m-b-30">
					<!-- Block2 -->
					<div class="block2">
						<div class="block2-pic">					
							<a href="#" class="block2-btn flex-c-m mtext-btn cl2 size-116 bg-btn-blue bor2 hov-btn-custom p-lr-15 trans-04">
								My Orders
							</a>
						</div>
					</div>
				</div>				
			</div>
			<div class="row isotope-grid m-t-80 d-none">			
				<div class="col-sm-6 col-md-4 col-lg-3 p-t-20 p-b-55 isotope-item m-b-80">
					<!-- Block2 -->
					<div class="block2">
						<div class="block2-pic">					
							<p class="block2-btn flex-c-m mtext-btn-bluegrey cl2 size-110 bg-btn-bluegrey bor2 p-lr-15 p-t-10 p-b-10 trans-04">
								Your account manager is 
								<br>
								{{this.accountManager}}
								<br>
								{{this.accountManagerPh}}
								<br>
								{{this.accountManagerEmail}}
							</p>							
						</div>
					</div>
				</div>				
				<div class="col-sm-6 col-md-4 col-lg-3 p-t-20 p-b-55 isotope-item">
					<!-- Block2 -->
					<div class="block2">
						<div class="block2-pic">					
							<p class="block2-btn flex-c-m mtext-btn-bluegrey cl2 size-110 bg-btn-bluegrey bor2 p-lr-15 p-t-10 p-b-10 trans-04">
								Your regional manager is 
								<br>
								{{this.regionalManager}}
								<br>
								{{this.regionalManagerPh}}
								<br>
								{{this.regionalManagerEmail}}
							</p>							
						</div>
					</div>
				</div>				
			</div>
		</div>
   </section>    
  </main>
<app-footer></app-footer>