<app-header></app-header>
<main id="main" class="p-t-50">
    <section id="speakers">
       <div class="container" data-aos="fade-up">

            <section class="bg0 p-t-5 p-b-80">
                <div class="container">
                <div class="col-sm-10 col-lg-7 col-xl-5 m-lr-auto m-b-50">
        
                    <div class="card pxy25">


                        <div class="section-header">
                            <h2>Customer Registration</h2>
                        </div> 


                        <div class="p-b-35 center full-width" (click)="hiddenfileinput.click()">
                            <!-- button class="stext-101 cl0 size-116 bg-btn-bluegrey bor14 hov-btn-custom p-lr-15 trans-04 pointer">
                                <a class="black-txt hov-btn-custom"><i class="zmdi zmdi-center-focus-weak"></i> &nbsp;  Browse Invoice</a>
                            </button -->
                            <!-- div class="text-center">
                                <input class="form-control" type="file" (change)="onChange($event)" />
                            </div -->
                            <input style="display: none" class="form-control" type="file" (change)="onChange($event)" accept="application/pdf" #hiddenfileinput>
                            <button  class="btn btn btn-info btn-block btn-rounded"  >&nbsp;  Browse Invoice </button> 
                            <div class="text-center">
                                <span class="stext-110 cl2"> {{selectedFileName}}</span>
                            </div>
                        </div>
                    
                        <div>
                            <div>
                                <span class="stext-110 cl2">
                                    Invoice Date
                                </span>
                            </div>
                            <div class="bor8 bg0 m-b-12 bg-disabled-text">
                                    <input class="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" placeholder="Invoice Date" [(ngModel)]="invoiceDate" [disabled]="true">
                            </div>
                        </div>
    
                        <div>
                            <div>
                                <span class="stext-110 cl2">
                                    Customer Name
                                </span>
                            </div>
                            <div class="bor8 bg0 m-b-12">
                                <input class="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" placeholder="Customer Name" [(ngModel)]="customerName">
                            </div>
                        </div>
    
                        <div>
                            <div>
                                <span class="stext-110 cl2">
                                    Mobile No
                                </span>
                            </div>
                            <div class="bor8 bg0 m-b-12">
                                <input class="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" placeholder="Mobile No" [(ngModel)]="customerMobile">
                            </div>
                        </div>
                                    
                        <div *ngIf="!showConfirm" class="col-sm-6 col-md-6 col-lg-6 p-b-35 center">
                            <div class="card-body"><button class="btn btn-primary"  (click)="getOTP()" > SEND OTP </button></div>
                           
                        </div>
    
                        <div *ngIf="showConfirm" class="col-sm-6 col-md-6 col-lg-6 p-b-35">
                            <p class="a-o-i">Enter verification code</p>
                            <!-- ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input -->
                            <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)" (fill)="handleFillEvent($event)">
                                </ngx-otp-input>
                        </div>
                        <div *ngIf="showConfirm" class="col-sm-6 col-md-6 col-lg-6 p-b-35 center">
                            <!-- span class="o-t-p">Entered otp :{{enteredOtp}}</span -->
                            <div class="card-body"><button class="btn btn-primary"  (click)="submitPurchase()" > CONFIRM </button></div>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        </div>
    </section>
  </main>
<app-footer></app-footer>