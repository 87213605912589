<app-header></app-header>
<main id="main" class="p-t-50">
    <section id="speakers">
       <div class="container" data-aos="fade-up">
            <section class="bg0 p-t-5 p-b-80">
                <div class="container">        
                    <div>
                        <span class="stext-110 cl2">
                            Please enter your address to send your FREE {{offerProductName}}
                        </span>
                    </div>
                                
					<div>
						<div>
							<span class="stext-110 cl2">
								Customer Name
							</span>
						</div>
						<div class="bor8 bg0 m-b-12 bg-disabled-text">
							<input class="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" [(ngModel)]="customerName" [disabled]="true">
						</div>
					</div>

					<div>
						<div>
							<span class="stext-110 cl2">
								Mobile No
							</span>
						</div>
						<div class="bor8 bg0 m-b-12 bg-disabled-text">
							<input class="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" [(ngModel)]="customerMobile" [disabled]="true">
						</div>
					</div>

                    <div>
                        <div>
                            <div>
                                <span class="stext-110 cl2">
                                    Door No
                                </span>
                            </div>
                            <div class="bor8 bg0 m-b-12">
                                <input class="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" placeholder="Door No" [(ngModel)]="customerDoorNo">
                            </div>
                        </div>
                        <div>
                            <div>
                                <span class="stext-110 cl2">
                                    Apartment Building
                                </span>
                            </div>
                            <div class="bor8 bg0 m-b-12">
                                <input class="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" placeholder="Apartment/Building" [(ngModel)]="customerApartment">
                            </div>
                        </div>
                    </div>                                

					<div>
						<div>
							<span class="stext-110 cl2">
								Address
							</span>
						</div>
						<div class="bor8 bg0 m-b-12">
							<input class="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" placeholder="Address" [(ngModel)]="customerAddress">
						</div>
					</div>

					<div>
						<div>
							<span class="stext-110 cl2">
								Pincode
							</span>
						</div>
						<div class="bor8 bg0 m-b-12">
							<input class="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" placeholder="Pincode" [(ngModel)]="customerPincode">
						</div>
					</div>

					<div class="col-sm-6 col-md-6 col-lg-6 p-b-35 center">
						<button class="flex-c-m stext-101 cl0 size-116 bg-btn-blue bor14 hov-btn-custom p-lr-15 m-t-50 trans-04 pointer">
							<a (click)="addOffer()" class="black-txt hov-btn-custom">SUBMIT</a>
						</button>
					</div>
                </div>
            </section>
        </div>
    </section>
  </main>
<app-footer></app-footer>