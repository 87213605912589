import { Component, OnInit } from '@angular/core';

//------------------- Add on imports ----------------------
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SharedDataService } from '../shared-data.service';
import { NotificationService } from '../notification.service';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})
export class CustomerDetailsComponent implements OnInit {
  constructor( 
    public apiService:ApiService, 
    private dataService: SharedDataService,
    private router: Router,
    private nofifyservice: NotificationService
  ){}


  offerData: any;
  offerProductName: any;
  customerName: any;
  customerMobile: any;
  customerDoorNo: any;
  customerApartment: any;
  customerAddress: any;
  customerPincode: any;
  accessoryid: any;

  validInputs: boolean = false;

  ngOnInit() { 
    this.validInputs= false;
    this.customerName = this.dataService.sessioncustomerData.customerName; 
    this.customerMobile = this.dataService.sessioncustomerData.customerMobile;
    this.offerProductName = this.dataService.sessionOfferProductData.accessory_name;
    this.accessoryid = this.dataService.sessionOfferProductData.accessory_id;

  }
  validateInputs () {
    if (this.customerDoorNo === "" || this.customerDoorNo === undefined) {
      this.nofifyservice.showWarning ('Please enter Door No','');
      this.validInputs= false;
    }
    else {
      this.validInputs= true;
    }
    if (this.customerApartment === "" || this.customerApartment === undefined) {
      this.nofifyservice.showWarning ('Please enter Apartment/Building','');
      this.validInputs= false;
    }
    else {
      this.validInputs= true;
    }
    if (this.customerAddress === "" || this.customerAddress === undefined) {
      this.nofifyservice.showWarning ('Please enter Address','');
      this.validInputs= false;
    }
    else {
      this.validInputs= true;
    }
    if (this.customerPincode === "" || this.customerPincode === undefined) {
      this.nofifyservice.showWarning ('Please enter Pin','');
      this.validInputs= false;
    }
    else {
      this.validInputs= true;
    }
    if (this.accessoryid === "" || this.accessoryid === undefined) {
      this.nofifyservice.showWarning ('Please select a valid offer product','');
      this.validInputs= false;
    }
    else {
      this.validInputs= true;
    }
  }
  addOffer() {
    this.validateInputs();
    if (this.validInputs) {
      this.offerData = '{' + '"accessory_id":' + '"' +  this.dataService.sessionOfferProductData.accessory_id + '",' 
                              + '"door_no":' + '"' + this.customerDoorNo  + '",' 
                              + '"apartment":' + '"' +  this.customerApartment + '",' 
                              + '"address":' + '"' +  this.customerAddress + '",' 
                              + '"pin":' + '"' +  this.customerPincode + '",' 
                              + '"offer_id":' + '"' +  this.dataService.sessionOfferProductData.accessory_id + '",' 
                              + '"product_id":' + '"' +  this.dataService.sessionproductData.productId + '",' 
                              + '"purchase_id":' + '"' +  this.dataService.sessionPurchaseData.purchase_id + '"' +
                            '}';
                            
        this.apiService.postRequest('/addOffer', this.offerData).subscribe((res: any)=> {
          if (typeof res ==='object') {
            if (res.msg === "Success") {
              console.log ("Offer added successfully.....");
            }
            else {
              this.nofifyservice.showWarning ("Unable to add offer product","");
            }
          }
        });  
      this.router.navigate(["/confirmpurchase"]);
    }
  }
}
