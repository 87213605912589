import { Component, OnInit } from '@angular/core';

//------------------- Add on imports ----------------------
import { Router } from '@angular/router';
import { SharedDataService } from '../shared-data.service';
import { ApiService } from '../api.service';
import { NotificationService } from '../notification.service';
import { HttpClient } from  '@angular/common/http';

@Component({
  selector: 'app-confirm-purchase',
  templateUrl: './confirm-purchase.component.html',
  styleUrls: ['./confirm-purchase.component.css']
})
export class ConfirmPurchaseComponent {
  constructor( 
    public apiService:ApiService,
    private dataService: SharedDataService,
    private nofifyservice: NotificationService,
    private http: HttpClient,
    private router: Router
  ){}

  customerName: any;
  customerMobile: any;
  purchaseID: any;
  formData: any;

  ngOnInit() { 
    this.customerMobile = this.dataService.sessioncustomerData.customerMobile;
    this.customerName = this.dataService.sessioncustomerData.customerName;
    this.purchaseID = this.dataService.sessionPurchaseData.purchase_id;
    //this.sendWhatsApp();
  }
  sendWhatsApp(){
     
    console.log ("Sending whatsapp message...");
 
    this.formData = '{' + '"purchase_id":' + '"' +  this.purchaseID  + '"}';
    // this.formData = '{' + '"purchase_id":' + '"1"}';
    this.apiService.postRequest('/sendWhatsappMessage', this.formData).subscribe((res: any) => {
      if (typeof res === 'object') {
        if (res.statusText==="Accepted") {            
            console.log ('Purchase details sent to registered WhatsApp number  successfully');
            this.nofifyservice.showSuccess ("Purchase details sent to Registered WhatsApp number.","");
        } 
        else {
          this.nofifyservice.showWarning ("Unable to send details via WhatsApp.","");
          return;
        }      
      }            
    }); 
  }  

}
